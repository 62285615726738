import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { ContactResponse } from 'models/contacts'

type CustomFields =
  | 'name'
  | 'domain'
  | 'origin_campaign'
  | 'deal_count'
  | 'sales_stages'
  | 'account_owners'
  | 'contact_labels'
  | 'company_tags'
  | 'starred_only'
  | 'email_validity'
  | 'has_linkedin'
  | 'email_type'
  | 'has_phone_number'
  | 'has_instagram'
  | 'company_type'
  | 'email_preferred'

export const CONTACTS_COLUMNS = {
  starred_only: {
    header: 'Starred',
    group: 'Activity',
    tooltipName: 'contacts_starred',
  },
  name: {
    header: 'Name',
    group: 'Contact Information',
  },
  domain: {
    header: 'Company Domain',
    group: 'Contact Information',
  },
  email_type: {
    header: 'Email Type',
    group: 'Contact Information',
  },
  has_phone_number: {
    header: 'Has Phone Number',
    group: 'Contact Information',
  },
  email_work: {
    header: 'Work Email',
    group: 'Contact Information',
  },
  email_personal: {
    header: 'Personal Email',
    group: 'Contact Information',
  },
  email_generic: {
    header: 'Other Email',
    group: 'Contact Information',
  },
  email_preferred: {
    header: 'Preferred Email',
    group: 'Contact Information',
    tooltipName: 'email_preferred',
  },
  email_validity: {
    header: 'Email Validity',
    group: 'Contact Information',
  },
  email_work_validity: {
    header: 'Work Email Validity',
    group: 'Contact Information',
    tooltipName: 'email_validity',
  },
  email_personal_validity: {
    header: 'Personal Email Validity',
    group: 'Contact Information',
    tooltipName: 'email_validity',
  },
  email_generic_validity: {
    header: 'Other Email Validity',
    group: 'Contact Information',
    tooltipName: 'email_validity',
  },
  has_instagram: {
    header: 'Has Instagram',
    group: 'Contact Information',
  },
  has_linkedin: {
    header: 'Has LinkedIn',
    group: 'Contact Information',
  },
  contact_company: {
    header: 'Company',
    group: 'Contact Information',
    tooltipName: 'contacts_contact_company',
  },
  title: {
    header: 'Title',
    group: 'Contact Information',
    tooltipName: 'contacts_title',
  },
  seniority: {
    header: 'Seniority',
    group: 'Contact Information',
  },
  department: {
    header: 'Department',
    group: 'Contact Information',
  },
  notes: {
    header: 'Notes',
    group: 'Activity',
    tooltipName: 'contacts_notes',
  },
  url: {
    header: 'Website',
    group: 'Contact Information',
    tooltipName: 'contacts_url',
  },
  linkedin_url: {
    header: 'LinkedIn',
    group: 'Contact Information',
    tooltipName: 'contacts_linkedin_url',
  },
  phone_number: {
    header: 'Phone Number',
    group: 'Contact Information',
    tooltipName: 'contacts_phone_number',
  },
  origin_campaign: {
    header: 'Requested From Campaign',
    group: 'Activity',
  },
  deal_count: {
    header: 'Deals',
    group: 'Account Details',
    tooltipName: 'contacts_deals_count',
  },
  sales_stages: {
    header: 'Sales Stages',
    group: 'Account Details',
    tooltipName: 'contacts_sales_stages',
  },
  account_owners: {
    header: 'Account Owners',
    group: 'Account Details',
    tooltipName: 'contacts_account_owners',
  },
  contact_labels: {
    header: 'Contact Labels',
    group: 'Account Details',
    tooltipName: 'contacts_tags',
  },
  company_tags: {
    header: 'Company Tags',
    group: 'Account Details',
    tooltipName: 'opps_tags',
  },
  company_size: {
    header: 'Company Size',
    group: 'Company Information',
    tooltipName: 'contacts_company_size',
  },
  company_cuisine_50: {
    header: 'Company Cuisine',
    group: 'Company Information',
    tooltipName: 'contacts_company_cuisine_50',
  },
  company_chain_instagram_handle: {
    header: 'Instagram Handle',
    group: 'Company Information',
    tooltipName: 'contacts_chain_instagram_handle',
  },
  source: {
    header: 'Source',
    group: 'Contact Information',
    tooltipName: 'contact_source',
  },
  created: {
    header: 'Date added',
    group: 'Activity',
    tooltipName: 'contacts_created',
  },
  modified: {
    header: 'Last updated',
    group: 'Activity',
    tooltipName: 'contacts_modified',
  },
  company_type: {
    header: 'Company Type',
    group: 'Contact Information',
    tooltipName: 'contacts_modified',
  },
} satisfies Partial<
  Record<keyof ContactResponse | CustomFields, DataTypeColumn>
>
