import BaseStep from './BaseStep'
import apiService from 'services/api'
import { useEffect, useMemo, useState } from 'react'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'
import { CreateEditCampaignModal } from 'features/campaigns/CreateEditCampaignModal'
import { FormCombobox } from 'components/FormUtils/FormCombobox'

const formShape = z.object({
  tags: z.array(z.number()),
  campaigns: z.array(
    z.object({
      campaign_id: z.number().optional(),
      sub_category_id: z.number(),
    })
  ),
})
export type FormType = z.infer<typeof formShape>

export function AddTagsStep() {
  const api = apiService()
  const { data: tagsOptions } = api.useGetTagsOptions(false, 'contacts')
  const { data: campaignOptions } = api.useGetCampaignsOptions()
  const { data: categories } = api.useGetCompanyCategoriesAndSub()
  const allSubCategories = categories?.flatMap((it) => it.sub_categories)

  const [openNewCampaign, setOpenNewCampaign] = useState(false)

  const {
    methods: { prevStep, handleClose, importContacts, setTags, setCampaigns },
    state: { previewData, file, isImporting, tags, campaigns },
  } = useImportContactContext()

  const { control, setError, watch } = useForm<FormType>({
    defaultValues: {
      tags: tags,
      campaigns: campaigns,
    },
  })

  const { fields, replace } = useFieldArray({
    control,
    name: 'campaigns',
  })

  const _tags = watch('tags')
  const _campaigns = watch('campaigns')

  useEffect(() => {
    setTags(_tags)
  }, [_tags])

  useEffect(() => {
    setCampaigns(_campaigns)
  }, [_campaigns])

  const subCategories = useMemo(
    () => [
      ...new Set(
        previewData?.success_contacts.map(
          (it) => it.contact_company.sub_category as unknown as number
        )
      ),
    ],
    [previewData]
  )

  useEffect(() => {
    replace(
      subCategories.map((it) => ({
        sub_category_id: it,
        campaign_id: undefined,
      }))
    )
  }, [subCategories])

  if (!previewData || !file) return null

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={() => prevStep()}
      onContinue={importContacts}
      title="Update New Contacts - Tag Contacts"
      backButtonText="Back"
      continueButtonText="Complete"
      isContinueLoading={isImporting}
    >
      <S.Content style={{ maxHeight: 500 }}>
        <S.ContentTitle>
          To organize these contacts, you can move their associated companies
          into a campaign, and/or apply labels directly to the contacts
          themselves. Note: The contact labels differ from company and deal
          tags, and are only assigned on the contact level.
          <div className="mt-2 flex items-center flex-col">
            <div className="max-w-[400px] w-[400px]">
              <CreatableTagSelector
                name="tags"
                label={`Add Contact Labels to ${previewData?.success_count} new contacts`}
                control={control}
                placeholder="Labels"
                options={tagsOptions}
                setError={setError}
                tagParent="contacts"
              />
              <div className="mt-3">
                <hr />
              </div>

              {fields.map((it: any, index) => {
                const sub = allSubCategories?.find(
                  (sub) => it.sub_category_id === sub.id
                )
                return (
                  <FormCombobox
                    key={it}
                    control={control}
                    name={`campaigns.${index}.campaign_id`}
                    label={`Add "${sub?.name}" companies to campaign:`}
                    placeholder="Select Campaign"
                    searchPlaceholder="Search Campaigns"
                    options={
                      campaignOptions?.filter(
                        (op) => op.companyTypeSlug === sub?.slug
                      ) ?? []
                    }
                    onAddNew={() => {
                      setOpenNewCampaign(true)
                    }}
                  />
                )
              })}
            </div>
            <CreateEditCampaignModal
              isOpen={openNewCampaign}
              onClose={() => setOpenNewCampaign(false)}
            />
          </div>
        </S.ContentTitle>
      </S.Content>
    </BaseStep>
  )
}
