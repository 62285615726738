import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { DataTable, DataTableProvider } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { IColumnVisibility } from '../../components/DataTable/types'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'
import apiService from 'services/api'

const TABLE_KEY = 'rebate-payments-table'

function RebatePaymentsTabComponent() {
  const api = apiService()
  const { data: invoicesData, isFetching } = api.useManufacturerInvoices()

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'created',
        header: 'Date',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'amount',
        header: 'Invoice Amount',
        size: 150,
        cell: (info) => `$${info.getValue().toFixed(2)}`,
      },
      {
        accessorKey: 'claim_count',
        header: '# of Claims',
        size: 120,
      },
      {
        accessorKey: 'due_date',
        header: 'SLA Date',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 120,
        cell: (info) => (
          <Badge
            bg={
              info.getValue() === 'PAID'
                ? 'success'
                : info.getValue() === 'OVERDUE'
                  ? 'danger'
                  : info.getValue() === 'PENDING'
                    ? 'warning'
                    : 'secondary'
            }
          >
            {info.getValue()}
          </Badge>
        ),
      },
      {
        id: 'actions',
        header: 'Actions',
        size: 100,
        cell: (info) => {
          const invoice = info.row.original
          if (invoice.stripe_invoice_url) {
            return (
              <Button
                variant="link"
                size="sm"
                href={invoice.stripe_invoice_url}
                target="_blank"
                rel="noopener noreferrer"
                className="p-0"
                title="View Invoice"
              >
                <FaExternalLinkAlt /> View Invoice
              </Button>
            )
          }
          return null
        },
      },
    ],
    []
  )

  return (
    <>
      <DataTableContainer className="h-full">
        <DataTable
          loading={isFetching}
          height={600}
          data={invoicesData?.results ?? []}
          columns={columns}
          tableKey={TABLE_KEY}
        />
      </DataTableContainer>
    </>
  )
}

export function RebatePaymentsTab({
  defaultColumnVisibility,
}: {
  defaultColumnVisibility?: IColumnVisibility<any>
}) {
  return (
    <ColumnsStoreProvider
      tableKey={TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={TABLE_KEY}>
        <RebatePaymentsTabComponent />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
