import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { Combobox, ComboboxProps } from 'components/UI/combobox/combobox'
import { ErrorLabel } from './ErrorLabel'

export interface FormComboboxProps<T extends FieldValues>
  extends Omit<ComboboxProps, 'onSelect' | 'selected'> {
  control: Control<T>
  name: Path<T>
  label?: string
  sublabel?: string
  optional?: boolean
  noMargin?: boolean
  helperText?: string
}

export function FormCombobox<T extends FieldValues>(
  props: FormComboboxProps<T>
) {
  const {
    control,
    name,
    label,
    sublabel,
    optional,
    noMargin,
    helperText,
    ...comboboxProps
  } = props

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <div className={`flex flex-col w-full ${noMargin ? 'mt-0' : 'mt-[10px]'}`}>
      <div className={noMargin ? 'mt-0' : 'mb-1'}>
        {label && (
          <label className="text-base font-medium text-gray-800 select-none">
            {label}
            {optional && (
              <span className="italic text-sm font-normal"> - optional</span>
            )}
          </label>
        )}
        {sublabel && (
          <label className="text-gray-600 text-sm font-normal leading-5">
            {sublabel}
          </label>
        )}
      </div>

      <Combobox
        {...comboboxProps}
        selected={value}
        onSelect={onChange}
        buttonClassName={`${comboboxProps.buttonClassName} ${
          error?.message ? 'border-red-500' : ''
        }`}
      />

      {helperText && (
        <span className="mt-1 text-gray-600 text-sm font-normal leading-5">
          {helperText}
        </span>
      )}
      {error?.message && <ErrorLabel message={error?.message} />}
    </div>
  )
}
