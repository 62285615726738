import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { ActivityV2 } from 'models/activityV2'
import CellEventFormatterV2 from './CellEvent/CellEventFormatterV2'
import { MdPersonOutline } from 'react-icons/md'
import { formatInteger } from 'utils/formatting'
import { contentTypeMap } from './utils'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { getCompanyUrl } from 'utils/getCompanyUrl'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { ACTIVITY_V2_COLUMNS } from 'components/DataExpansionComponents/Activity/activityV2ColumnInfo'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { Fragment } from 'react'
import { ContactCompanyType } from 'models/contact_companies'

export const useActivityTableColumnsV2 = (): ColumnDef<ActivityV2, any>[] => {
  const { preferences } = usePreferences()
  const columnHelper = createColumnHelper<ActivityV2>()

  const activityColumnHelper = createTableColumnHelper({
    columns: ACTIVITY_V2_COLUMNS,
  })
  return [
    columnHelper.accessor('user', {
      ...activityColumnHelper.retrieveColumn('user'),
      cell: ({ row }) => {
        const user = row.original.user
        return (
          <div className="d-flex align-items-center gap-1">
            <MdPersonOutline size={20} />
            <div>
              {user.first_name} {user.last_name}
            </div>
          </div>
        )
      },
    }),
    columnHelper.display({
      ...activityColumnHelper.retrieveColumn('event'),
      size: 300,
      cell: ({ row }) => {
        return CellEventFormatterV2({ event: row.original })
      },
    }),
    columnHelper.accessor('bulk_size', {
      ...activityColumnHelper.retrieveColumn('bulk_size'),
      size: 100,
      cell: ({ getValue }) => {
        const value = getValue()
        return formatInteger(value)
      },
    }),
    columnHelper.accessor('content_type', {
      ...activityColumnHelper.retrieveColumn('content_type'),
      cell: ({ row }) => {
        return contentTypeMap[row.original.content_type.model]
      },
    }),
    columnHelper.display({
      ...activityColumnHelper.retrieveColumn('company'),
      cell: ({ row }) => {
        if (row.original.bulk_size === 1) {
          const company = row.original.changes[0]?.contact_company
          if (!company) return null
          return (
            <FbLink
              to={`${getCompanyUrl(company)}`}
              target="_blank"
              rel="noreferrer"
            >
              {company.name}
            </FbLink>
          )
        }
        const uniqueCompanies = new Map()
        row.original.changes.forEach((change) => {
          const company = change.contact_company
          if (company && !uniqueCompanies.has(company.name)) {
            uniqueCompanies.set(company.name, company)
          }
        })
        const contact_companies = Array.from(uniqueCompanies.values()).slice(
          0,
          20
        ) // Limit to 20 distinct companies
        const tooltipText = contact_companies
          .map((company) => company?.name)
          .join(', ')
        return (
          <OverflownTextTooltip
            tooltipText={`${tooltipText}, ... (${row.original.changes.length - 20} more)`}
            maxLines={2}
          >
            {contact_companies.map((company, index) => (
              <Fragment
                key={'contact-companies-link-activity-table' + company?.id}
              >
                <FbLink
                  to={`/chain/${preferences?.company_type_prefix_map[ContactCompanyType.Chain]}${company?.chain}`}
                >
                  {company?.name}
                </FbLink>
                {index < contact_companies.length - 1 && ', '}
              </Fragment>
            ))}
          </OverflownTextTooltip>
        )
      },
    }),
    columnHelper.accessor('created_at', {
      ...activityColumnHelper.retrieveColumn('created_at'),
      cell: ({ row }) => {
        const date = new Date(row.original.created_at)
        // Date needs to be formatted like this: May 16, 2023,  4:14 PM
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      },
    }),
  ]
}
